<template>
  <div style="color: #fff">{{ ErrorMsg }}</div>
</template>

<script>
import querystring from "querystring";
import { Login,QYLogin } from "@/api/index.js";
import { setSessionObj, setSessionStr } from "@/utils/session.js";

export default {
  data() {
    return {
      ErrorMsg: "",
      DetailId: 0,
      reporttype: 1,
    };
  },

  mounted() {
    let par = location.hash;
    par = decodeURIComponent(par.substring(par.indexOf("?") + 1, par.length));
    let objUser = querystring.parse(par.replace("?pid=", "&pid="));
    //console.log(objUser);
    this.$store.commit("setOpenID", objUser.pid);
    this.$store.commit("setCorpID", objUser.cid);
    this.$store.commit("setFaceUrl", objUser.furl);
    this.DetailId = objUser.id;
    this.reporttype = objUser.type == "AbnormalNotify" ? 2 : 1;
    if (objUser.pid != undefined && objUser.pid != "") {
      if (objUser.cid != undefined && objUser.cid != "")
        this.UserLogin1(objUser.cid, objUser.pid);
      else this.UserLogin(objUser.pid);
    }
  },

  methods: {
    UserLogin(_openid) {
      Login(
        {
          openId: _openid,
          userName: "",
          password: "",
          captcha: "",
          verKey: "",
        },
        (ret) => {
          this._login(ret);
        }
      );
    },

    UserLogin1(_cid, _uid) {
      QYLogin(
        {
          wwUserId: _uid,
          wwCompanyId: _cid,
        },
        (ret) => {
          this._login(ret);
        }
      );
    },

    _login(ret) {
      if (ret) {
        if (ret.code == 0) {
          this.$store.commit("setToken", ret.data.token);
          this.$store.commit("setUser", ret.data.user);
          this.$store.commit("setCompanys", ret.data.companys);
          this.$store.commit("setDepts",this.$Tools.sortBykey(ret.data.depts, "id"));
          this.$store.commit("setGroup", ret.data.group);
          this.$store.commit("setInspector", ret.data.inspector);
          this.$store.commit("setPermission", ret.data.permission);
          this.$store.commit("setPlace", ret.data.place);
          this.$store.commit("setRoutes",this.$Tools.sortBykey(ret.data.routes, "id"));
          this.$store.commit("setDevices", ret.data.devices==undefined?[]:ret.data.devices);

          //初始化查询条件
          let date1 = this.$Tools.FormatDate2(new Date()) + " 00:00:01";
          let date2 = this.$Tools.FormatDate2(new Date()) + " 23:59:59";
          setSessionStr("Query_Date", this.$t("lang.today"));
          setSessionStr("Query_Date_1", date1);
          setSessionStr("Query_Date_2", date2);

          let com_name =
            ret.data.companys.length > 0 ? ret.data.companys[0].name : "";
          let com_id =
            ret.data.companys.length > 0 ? ret.data.companys[0].id : 0;
          setSessionStr("Query_Company", com_name);
          setSessionStr("Query_Company_id", com_id);

          let dept_name = "";
          let dept_id = 0;
          if (ret.data.depts.length > 0) {
            let lstData = ret.data.depts.filter((d) => {
              return d.companyId == com_id;
            });
            dept_name = lstData.length > 0 ? lstData[0].name : "";
            dept_id = lstData.length > 0 ? lstData[0].id : 0;
          }
          setSessionStr("Query_Dept", dept_name);
          setSessionStr("Query_Dept_id", dept_id);
          setSessionStr("Query_RepairType", "-1");
          setSessionStr("Query_Record_miss", "0");
          setSessionStr("Query_Record_local", "1");

          //参数
          let par = {
            mCompany: com_id,
            mDept: dept_id,
            mDate1: date1,
            mDate2: date2,
            mLine: "",
            mAddr: "",
            mGroup: "",
            mUser: "",
            mDevice:"",
            mRecord: "-1",
            mRecord_miss: "0",
            mRecord_local: "1",
            mState: "",
            mOrder: "desc",
            mTotalType: "4",
            mRepairType: "-1",
            mKeyword: "",
            pagesize: 20,
          };
          setSessionObj("Query_Params", par);

          this.$router.replace({
            path:
              this.reporttype == 1
                ? "/repair/detail"
                : "/repair/abnormaldetail",
            query: { id: this.DetailId },
          });
        } else if (ret.code != 9) this.ErrorMsg = ret.message;
      } else this.ErrorMsg = this.$t("lang.login_fail");
    },

    beforeCreate() {
      document
        .querySelector("body")
        .setAttribute("style", "background-color:#336666");
    },
    //#0184b2
    beforeDestroy() {
      document.querySelector("body").setAttribute("style", "");
    },
  },
};
</script>
